import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberOnlyDirective } from "../services/directives/number-only/number-only.directive"
import { appNoSpaceAtFirst } from "../services/directives/no-spce/no-space.directive";
import { NumberDotOnlyDirective } from "../services/directives/number-dot-only/number-dot-only.directive"

@NgModule({
  declarations: [
    NumberOnlyDirective,
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
  ],
  imports: [CommonModule],
  exports: [NumberOnlyDirective, appNoSpaceAtFirst, NumberDotOnlyDirective],
})
export class NumberModuleModule {}
